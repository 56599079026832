import './App.scss';
import {ChakraProvider} from "@chakra-ui/react"
import {Stepper} from './comnponents/Stepper';
import ReactGA from "react-ga4";
import {IntlProvider} from "react-intl";
import German from './lang/de.json';
import moment from "moment";
import 'moment/locale/fr';
import 'moment/locale/de';
import 'moment/locale/da';
import 'moment/locale/nb';
import 'moment/locale/nl';
import 'moment/locale/sv';
import 'moment/locale/es';
import 'moment/locale/en-gb';

let messages = German;
let locale = 'de';
moment.locale('de');

document.title = 'Pro Kapseln - ' + messages.title;

function App() {
    ReactGA.initialize('G-QQYFLQM9QR');
    ReactGA.send({hitType: 'pageview', page: window.location.pathname + window.location.search});

    return (
        <div className="App">
            <IntlProvider locale={locale} messages={messages} defaultLocale="de">
                <ChakraProvider>
                    <Stepper/>
                </ChakraProvider>
            </IntlProvider>
        </div>
    );
}

export default App;
